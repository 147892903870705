<template>
  <base-chats :data="chatList" :loading="loading" @search="search($event)" />
</template>

<script>
export default {
  components: {
    baseChats: () => import("./baseChats.vue"),
  },
  props: {
    id: { type: Number, default: 0 },
  },
  data() {
    return {
      chatList: [],
      loading: false,
      defaults: {
        sort: { key: "id", order: "ASC" },
      },
    };
  },
  created() {
    this.$root.title = "Чат";
    console.log("created chat room");
    this.fitchData();
  },
  computed: {},
  watch: {},
  methods: {
    refresh() {
      this.fitchData();
    },
    async fitchData() {
      if (this.loading) return;
      this.loading = true;
      let resp;
      this.chatList = [];
      const params = {
        filters: { lead_id: this.id || undefined },
        sort: { key: "last_message_createdon", order: "desc" },
      };
      resp = await this.$axios.get("/mechti/chats", { params }); //last_message_createdon

      if (resp.data.data.length > 0) {
        this.chatList = resp.data.data;
      } else {
        this.afterFitch();
        return;
      }
      this.afterFitch();
      return;
    },
    afterFitch() {
      this.loading = false;
    },
  },
};
</script>
